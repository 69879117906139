import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import accountClient from 'api/accountClient';
import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import { getAzureLoginUrl, SignUpSignInJourneyStep } from 'helpers/azureRoutingHelper';
import { accountRoutes } from 'helpers/routingHelper';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import { useUser } from 'state/user/state';

const ForgottenPassword: React.FC = () => {
  const metaTitle = 'Forgotten password';

  const [user] = useUser();
  const { requestHandler } = useApiRequestHandler();

  useEffect(() => {
    if (!user) {
      return;
    }
    if (user?.isLoggedIn) {
      navigate(accountRoutes.account);
      return;
    }
    requestHandler(() =>
      accountClient.getNonce().then(({ nonce }) => {
        window.location.replace(
          getAzureLoginUrl(SignUpSignInJourneyStep.ForgotPassword, nonce)
        );
      })
    );
  }, [requestHandler, user]);

  return (
    <Layout meta={{ meta_title: metaTitle }} pageType="accountArea" hideAccountMenu>
      <LoadingOverlay
        loadingMessage={
          user?.isLoggedIn === false
            ? 'Redirecting to forgot password page'
            : 'Fetching user session'
        }
      />
    </Layout>
  );
};

export default ForgottenPassword;
